<template>
  <page-container title="绿化情况" :breadcrumb="breadcrumb">
    <!--    查询栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="userdepid">
              <a-cascader  :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" v-model="queryParams.selectDeptList" :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]" :load-data="loadChildrenDept" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="植物名称" prop="name">
              <a-input v-model.trim="queryParams.name" placeholder="请输入植物名称"></a-input>
            </a-form-model-item>

            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开
                <a-icon :type="showAdvanced?'up':'down'"></a-icon>
              </a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="科属" prop="family">
              <a-input v-model="queryParams.family" placeholder="请输入科属"></a-input>
            </a-form-model-item>
            <a-form-model-item label="位置" prop="location">
              <a-input v-model="queryParams.location" placeholder="请输入位置"></a-input>
            </a-form-model-item>
            <a-form-model-item label="单位" prop="unit">
<!--              <a-input v-model="queryParams.unit" placeholder="请输入单位"></a-input>-->
              <a-select v-model="queryParams.unit">
                <a-select-option value="">全部</a-select-option>
                <a-select-option v-for="(item, index) in unitList" :key="index" :value="item.dicvalue">
                  {{ item.dicvalue }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="数量" prop="nums">
              <a-input v-model="queryParams.nums" placeholder="请输入数量"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <!--    展示列表栏-->
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">绿化情况列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')">
              <a-icon type="plus"></a-icon>
              新建
            </a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="id" :loading="tableLoading"
                 :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')"
                 style="margin-top: 20px;" :pagination="false"  :customRow="handleClickRow">
          <!--          <span slot="status" slot-scope="text" class="projectInfoAfforests_status-view">-->
          <!--            <span class="dot" :class="{'red': text=='1'}"></span>-->
          <!--            <span class="text">{{ text == '1' ? '绿化' : '结清' }}</span>-->
          <!--          </span>-->
          <span slot="people_type" slot-scope="text" class="projectInfoAfforests_people_type-view">
            <span class="dot" :class="{'green': text===1}"></span>
            <span class="text">{{ text === 0 ? '使用人' : '业主' }}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.id">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.id">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer
                      @change="pageChange" @showSizeChange="onShowSizeChange"
                      :show-total="(total, range) => `共${total}条`"
                      style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <!--    新建、修改绿化页面-->
    <a-modal v-model="modalVisible" :title="modalTitle" :width="900" :dialog-style="{top: '25px'}"
             :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalCancel">{{modalType==='detail'?'关闭':'取消'}}</a-button>
        <a-button v-if="modalType=='add'||modalType=='edit'"  type="primary" @click="modalConfirm">提交</a-button>
      </template>
      <a-form-model ref="modalForm" :model="modalForm" :rules="modalRules" layout="inline" class="form-in-twocolumns"
                    :label-col="{span:9}" :wrapper-col="{span:14}">
        <a-form-model-item label="公司/项目" prop="userdepid">
          <a-cascader :disabled="modalType==='detail'"  :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" v-model="modalForm.selectDeptList" :options="deptOptions?deptOptions.filter(item => item.deptype===1):[]" :load-data="loadChildrenDept" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="植物名称" prop="name">
          <a-input :disabled="modalType==='detail'" v-model.trim="modalForm.name" placeholder="请输入植物名称"></a-input>
        </a-form-model-item>
        <a-form-model-item label="科属" prop="family">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.family" placeholder="请输入科属"></a-input>
        </a-form-model-item>
        <a-form-model-item label="位置" prop="location">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.location" placeholder="请输入位置"></a-input>
        </a-form-model-item>
        <a-form-model-item label="单位" prop="unit">
<!--          <a-input v-model="modalForm.unit" placeholder="请输入单位"></a-input>-->
          <a-select :disabled="modalType==='detail'" v-model="modalForm.unit">

            <a-select-option v-for="(item, index) in unitList" :key="index" :value="item.dicvalue">
              {{ item.dicvalue }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="数量" prop="nums">
          <a-input :disabled="modalType==='detail'" v-model="modalForm.nums" placeholder="请输入数量"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import {
  addPerformanceProjectInfoAfforest,
  getProjectInfoAfforestListByCondition,
  deleteOneProjectInfoAfforest,
  editProjectInfoAfforest
} from 'A/performance'
import { getDictByDicType} from 'A/xtpz'
import {
  getItemFromArrayByKey,
} from 'U'
import moment from 'moment'
import {mapState} from "vuex";
import deptselect from '@/mixins/deptselect'
import {userdep} from "V/dataAnalysis/performance/minins/userdep";

export default {
  name: 'projectInfoAfforest',
  mixins: [deptselect,userdep],
  data() {
    return {
      showAdvanced: false,
      moment,
      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      breadcrumb: [
        {
          name: '业务管理',
          path: ''
        },
        {
          name: '履约管理',
          path: ''
        },
        {
          name: '履约数据',
          path: ''
        },
        {
          name: '项目情况简介',
          path: ''
        },
        {
          name: '绿化',
          path: ''
        }
      ],
      queryParams: {
        name:'',
        family:'',
        location:'',
        unit:'',
        nums:'',
        userdepid:'',
        selectDeptList:[]
      },
      tableColumns: [
        {
          title: '公司/项目',
          dataIndex: 'userdepname',
          key: 'userdepname'
        },
        {
          title: '植物名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '科属',
          dataIndex: 'family',
          key: 'family'
        },
        {
          title: '位置',
          dataIndex: 'location',
          key: 'location'
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key: 'unit',
        },
        {
          title: '数量',
          dataIndex: 'nums',
          key: 'nums',
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: {customRender: 'operation'}
        },
      ],
      tableData: [],
      tableLoading: false,
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
      modalVisible: false,
      modalType: '',
      modalForm: {
        id:0,
        name:'',
        family:'',
        location:'',
        unit:'',
        nums:'',
        userdepid:"",
        selectDeptList:[],
      },
      modalRules: {
        name: [{required: true, message: '请输入植物名称'}],
        family: [{required: true, message: '请输入植物科属'}],
        location: [{required: true, message: '请输入位置'}],
        unit: [{required: true, message: '请选择单位'}],
        nums: [{required: true, message: '请输入数量'}],
        userdepid: [{required: true, message: '请选择公司/项目'}]
      },
      selectedProjectInfoAfforest:null,
      unitList:[]
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      let title = '';
      if (this.modalType == 'add') {
        title = '新增';
      } else if (this.modalType == 'detail') {
        title = '详情';
      } else if (this.modalType == 'edit') {
        title = '修改';
      } else {
        title = '';
      }
      return title;
    },
    //查询绿化单时间
    query_begin_time() {
      if (this.queryParams.time_interval && this.queryParams.time_interval[0]) {
        return moment(this.queryParams.time_interval[0]).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
    query_endtime() {
      if (this.queryParams.time_interval && this.queryParams.time_interval[1]) {
        return moment(this.queryParams.time_interval[1]).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
    //添加绿化单时间
    add_happen_time() {
      if (this.modalForm.happen_time) {
        return moment(this.modalForm.happen_time).format('YYYY-MM-DD');
      } else {
        return ''
      }
    },
  },
  watch: {
    'modalForm.selectDeptList'(val) {
      if(val && val.length) {
        this.modalForm.userdepid = val.join('|')
        this.$refs.modalForm.clearValidate('userdepid');
        // this.initMaintenancegroupidOptions();
      }else {
        this.modalForm.userdepid = ''
      }
    },
    'queryParams.selectDeptList'(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
        // this.queryParams.userdepid = val.join('|')
        // this.$refs.queryForm.clearValidate('userdepid');
        // this.initMaintenancegroupidOptions();
      }else {
        this.queryParams.userdepid = ''
      }
    },
  },

  created() {
    this.queryParams.userdepid=this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid
    this.init();
  },
  methods: {
    init() {
      this.getUnit()
      this.getTableData();
    },
    resetQueryParams() {
      this.queryParams.selectDeptList=[]
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getUnit(){
      let params = {
        //这个要对照数据库
        dictype: '84'
      };
      getDictByDicType(params).then(res => {
        if (res && res.returncode === '0') {
          //console.log(res.item)
          this.unitList = res.item ? res.item : []
        }
      })
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;

      // let userdepid = this.queryParams.userdepid;
      // if(userdepid.indexOf('|') > -1) {
      //   userdepid = userdepid.substr(userdepid.lastIndexOf('|')+1);
      // }
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
        begin_time: this.query_begin_time,
        end_time:this.query_endtime,
        //userdep_id:this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid,
        // userdepid:this.userInfo.useraccount==='admin'?null:this.userInfo.userdepid,
      };
      getProjectInfoAfforestListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode === '0') {
          // this.tableData = res.item?res.item.map(item => ({
          //   ...item,
          //   time_interval: item.status===1?item.begin_time+'~至今':item.begin_time+'~'+item.end_time
          // })):[]
          this.tableData = res.item?res.item:[]
          this.pagination.total = res.count;
        }
      }).catch(err => {
        console.log(err)
        this.tableLoading = false;
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    showModal(type, value, record) {
      this.modalType = type;
      if (type == 'add') {
        this.modalVisible = true;
        this.modalForm.id=0
        this.modalForm.name=''
        this.modalForm.family=''
        this.modalForm.location=''
        this.modalForm.unit=''
        this.modalForm.nums=''
        this.modalForm.userdepid= ''
        this.modalForm.selectDeptList= []
      } else {
        this.initUserDeptid(record.userdep_id)
        this.modalVisible = true
        this.modalForm.id=record.id
        this.modalForm.name=record.name
        this.modalForm.family=record.family
        this.modalForm.location=record.location
        this.modalForm.unit=record.unit
        this.modalForm.nums=record.nums
        this.modalForm.userdepid = record.userdep_id
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.id);
      }).catch(()=>{
      });
    },
    delete(projectInfoAfforest_id) {
      if (projectInfoAfforest_id) {
        let params = {
          projectInfoAfforest_id
        };
        deleteOneProjectInfoAfforest(params).then(res => {
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    modalConfirm() {
      if (this.modalType == 'add' || this.modalType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.addOrEdit();
          } else {
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    //添加或者编辑绿化单
    addOrEdit() {
      this.showLoading();
      console.log(this.modalForm)
      let userdepid = this.modalForm.userdepid;
      if(userdepid.indexOf('|') > -1) {
        userdepid = userdepid.substr(userdepid.lastIndexOf('|')+1);
      }
      let params = {
        name:this.modalForm.name,
        family:this.modalForm.family,
        location:this.modalForm.location,
        unit:this.modalForm.unit,
        nums:this.modalForm.nums,
        userdep_id: userdepid
      };
      if (this.modalType == 'add') {
        console.log(params)
        addPerformanceProjectInfoAfforest(params).then(
            this.addOrEditResult);
      } else if (this.modalType == 'edit') {
        params.id = this.modalForm.id;
        console.log(params)
        editProjectInfoAfforest(params).then(this.addOrEditResult)
      }
    },
    addOrEditResult(res) {
      this.hideLoading();
      if (res && res.returncode == '0') {
        this.$message.success('操作成功');
        this.modalVisible = false;
        this.getTableData();
      } else {
        this.$message.error(res.errormsg || '操作失败');
      }
    },
    modalCancel() {
      this.modalVisible = false;
    },
    resetModal() {
      this.$refs.modalForm.resetFields();
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      console.log(arr)
      this.selectedProjectInfoAfforest = getItemFromArrayByKey(this.tableData, 'id', id);
      console.log(this.selectedProjectInfoAfforest)
      if ('edit' == type) {
        this.showModal('edit', id, this.selectedProjectInfoAfforest)
      } else if ('detail' == type) {
        this.showModal('detail', id, this.selectedProjectInfoAfforest)
      }else if ('delete' == type) {
        this.deleteConfirm(id, this.selectedProjectInfoAfforest)
      }
    },
    handleClickRow(record,index){
      console.log(record)
      return {
        on: {
          click: () => {
            this.operationClick({key:"detail-"+String(record.id)});
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.menu-modal-container {
  // display: flex;
  // position: relative;
  height: 100%;
  overflow-y: auto;
  margin-right: -24px;
}
.projectInfoAfforests_status-view {
  display: flex;
  align-items: center;
  .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #65ff18;
    border-radius: 50%;
    &.red {
      background-color: #e70c0c;
    }
  }
  .text {
    display: inline-block;
    margin-left: 7px;
  }
}
.projectInfoAfforests_people_type-view {
  display: flex;
  align-items: center;
  .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #1859ff;
    border-radius: 50%;
    &.green {
      background-color: #65ff18;
    }
  }
  .text {
    display: inline-block;
    margin-left: 7px;
  }
}
</style>